/* Accordion.module.scss */
@import '../../styles/main.scss';

.accordionRootContainer {
  padding: $universalSize-96 $universalSize-24;

  @include respond(medium) {
    padding: $universalSize-40 $universalSize-24;
  }
}
// Accordion Section heading
.sectionHeading {
  text-align: center;
  margin: 0 auto;
  max-width: $universalSize-980;

  @include respond(small) {
    text-align: left;
  }

  h2 {
    @include desktopHeading2();

    @include respond(medium) {
      @include mobileHeading2();
    }
  }

  h3 {
    @include desktopHeading3();

    @include respond(medium) {
      @include mobileHeading3();
    }
  }
  
  p {
    @include commonParagraph($universalSize-24, $universalSize-16, $text-color-2);
  }
}

.fieldset {
  border: 0;
}

.fullWidthRootContainer {
  display: flex;
  flex-direction: column;
  margin-top: $universalSize-64;
}

.halfWidthRootContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  max-width: $universalSize-980;
  margin-top: $universalSize-64;

  @include respond(medium) {
    margin-top: $universalSize-32;
    flex-direction: column;
  }
}

.fullWidthContainer {
  width: 100%;
}

.halfWidthContainer {
  width: 50%;

  @include respond(medium) {
    width: 100%;
    order: 1;
    margin-top: $universalSize-24;
  }
}

.accordion {
  margin: 0rem auto;
  margin: 0 auto;
  max-width: $universalSize-980;

  &.active {
    display: block;
  }

  &.inactive {
    display: none;
  }
}

.accordionItem {
  border-radius: $universalSize-24;
  background: #f5f3fa;
  margin-bottom: $universalSize-24;

  .accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $universalSize-24;
    cursor: pointer;

    h3,
    h4,
    h5,
    h6 {
      flex-basis: 95%;

      @include desktopHeading4();
      @include respond(medium) {
        @include mobileHeading4();
      }
    }
  }

  .accordionContent {
    display: none;
    padding: 0rem $universalSize-24 $universalSize-24 $universalSize-24;
  }

  .toggleButton {
    flex-basis: 5%;
    width: $universalSize-20;
    height: $universalSize-20;
    position: relative;
    cursor: pointer;

    img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
    }
  }

  .toggleInput {
    display: none;

    &:checked + .accordionHeader + .accordionContent {
      display: block;
    }
    &:checked + .accordionHeader .toggleButton .plusIcon {
      display: none;
    }
    & + .accordionHeader .toggleButton .plusIcon {
      display: block;
    }
    &:checked + .accordionHeader .toggleButton .minusIcon {
      display: block;
    }
    & + .accordionHeader .toggleButton .minusIcon {
      display: none;
    }
  }

  .para {
    p {
      @include commonParagraph(null, null, $text-color);
    }

    ul {
      padding: 0;
      margin-left: $universalSize-40;

      li {
        @include commonParagraph(null, null, $text-color);
      }
    }
  }
}

.imageContainer {
  flex: 1;
}

.imageWrapper {
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 95%;
  border-radius: $universalSize-40;
  margin-left: $universalSize-24;

  @include respond(medium) {
    border-radius: $universalSize-8;
    margin-left: 0rem;
  }
}

.toggleInput:checked ~ .accordionHeader .toggleButton .plusIcon {
  display: none;
}

.toggleInput:checked ~ .accordionHeader .toggleButton .minusIcon {
  display: block;
}

.toggleInput:checked ~ .accordionContent {
  display: block;
}

.toggleInput:checked ~ .imageContainer .image {
  display: inline-block;
}
