@import '../../styles/main.scss';

.authorRootContainer {
  padding: $universalSize-96 $universalSize-24;

  @include respond(medium) {
    padding: $universalSize-40 $universalSize-24;
  }

  .authorContainer {
    margin: 0 auto;
    max-width: $universalSize-980;

    border: $universalSize-1 solid #e8e8e8;
    border-radius: $universalSize-32;
    padding: $universalSize-40;

    .mainAuthorWidgetSection {
      display: flex;
      flex-direction: row;
      gap: $universalSize-12;

      .authorMainInfo {
        .authorTitle {
          @include commonParagraph(null, null, $text-color);
          font-size: $universalSize-16;
          font-weight: 600;

          .authorLink {
            -webkit-tap-highlight-color: transparent;
            text-decoration: none;
          }

          .authorLink:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .authorFollowersAndReviewer {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: $universalSize-8;
          margin-top: $universalSize-4;

          @include respond(medium) {
            flex-direction: column;
            align-items: flex-start;
          }

          p {
            color: #5c5677;
            font-size: $universalSize-14;
            font-style: normal;
            font-weight: 400;
            line-height: $universalSize-20;
            display: flex;
            align-items: center;
            gap: $universalSize-8;
          }
        }

        .authorBio {
          color: #000;
          font-size: $universalSize-14;
          font-style: normal;
          font-weight: 400;
          line-height: $universalSize-20;
          margin-top: $universalSize-16;
        }
      }
    }
  }
}