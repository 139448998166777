@import '../../styles/main.scss';

.bottomFloaterRootContainer {
  position: relative;
}

.bottomFloaterContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 14;
  background: radial-gradient(165.46% 111.8% at 50% 0%, color(display-p3 0.23 0.09 0.47) 0%, color(display-p3 0.098 0.0941 0.2667) 50%, color(display-p3 0.07 0.07 0.07) 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $universalSize-16 $universalSize-40;
  overflow: hidden;
  max-height: 0; /* Set initial max-height to 0 */
  animation: slideIn 0.75s ease forwards;

  @keyframes slideIn {
    0% {
      transform: translateY(100%); /* Start with translateY(100%) */
      max-height: 0; /* Start with max-height 0 */
    }
    100% {
      transform: translateY(0);
      max-height: 100vh; /* Expand max-height to full viewport height */
    }
  }

  @media (min-width: 761px) and (max-width: 1190px) {
    padding: 1.25rem 1.5rem;
  }

  @include respond(medium) {
    padding: $universalSize-12 $universalSize-24 $universalSize-20 $universalSize-24;
  }

  @include respond(small) {
    padding: 0.75rem 1.25rem 1.25rem 1.25rem;
  }

  .backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .sectionHeading {
    padding-right: $universalSize-20;

    @media (min-width: 761px) and (max-width: 1190px) {
      padding-right: 0rem;
    }

    p {
      @include desktopPara2();
    }

    p:nth-child(1) {
      color: white;
    }

    p:nth-child(2) {
      color: $bottom-floater-text-color;
    }

    @include respond(medium) {
      p {
        @include mobilePara2();
        font-size: $universalSize-16;
        line-height: $universalSize-24;
      }
    }
  }

  .gridSectionForData {
    display: flex;
    justify-content: center;
    align-items: center;

    .floaterDataSection {
      border-right: 1px solid $white-transparent;
      padding: 0 $universalSize-50;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      @media (min-width: 761px) and (max-width: 1190px) {
        padding: 0 1.2rem;
      }

      &:nth-child(3) {
        border-right: none;
      }

      p:nth-child(1) {
        @include desktopPara2();
        color: $white-color;
      }

      p:nth-child(2) {
        @include desktopPara3();
        color: $white-transparent;
      }
    }
    @include respond(medium) {
      display: none;
    }
  }
}
